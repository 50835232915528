





















































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import TextField from "@/components/formbuilder/TextField.vue";
import TextAreaField from "@/components/formbuilder/TextAreaField.vue";
import SelectField from "@/components/formbuilder/SelectField.vue";
import FormFieldList from "@/components/formbuilder/FormFieldList.vue";
import FormFieldSettings from "@/components/formbuilder/FormFieldSettings.vue";
import { ClientConsultationForm, Form, Role } from "@/types";
import FormBackgroundImage from "@/components/formbuilder/FormBackgroundImage.vue";
import formStoreModule from "@/store/modules/clientConsultationForm";
import formValuesStoreModule from "@/store/modules/consultationFormValues";
import Signature from "@/components/Signature.vue";

const { mapActions: formActions } = createNamespacedHelpers(
  "CLIENT_CONSULTATION_FORM"
);

const { mapActions: formValuesActions } = createNamespacedHelpers(
  "CLIENT_CONSULTATION_FORM_VALUES"
);

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  components: {
    TextField,
    TextAreaField,
    SelectField,
    FormFieldList,
    FormFieldSettings,
    FormBackgroundImage,
    Signature,
  },
  name: "ClientConsultationForm",
  props: {
    formId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    apiUrl: `${VUE_APP_API_URL}/v1/file`,
    valid: false,
    showDialog: false,
    signature: "",
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    consultationForm(): ClientConsultationForm {
      return this.$store.getters[
        "CLIENT_CONSULTATION_FORM/getConsultationForm"
      ](this.formId);
    },
  },
  created() {
    this.fetchClientConsultationForm(`/${this.formId}`);
  },
  methods: {
    ...formActions(["fetchClientConsultationForm"]),
    ...formValuesActions(["createConsultationFormValues"]),
    validateForm() {
      const valid = (
        this.$refs.form as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      const signature = (this.$refs.signature as any).save();

      if (!signature) return;

      const payload = {
        consultationFormId: this.consultationForm._id,
        signature,
        formValues: (this.consultationForm.form as Form).formFields.map(
          (ff) => ({
            fieldId: ff._id,
            value: ff.value,
          })
        ),
      };

      this.createConsultationFormValues(payload).then((data) => {
        if (data) {
          this.showDialog = true;
        }
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT_CONSULTATION_FORM")) {
      this.$store.registerModule("CLIENT_CONSULTATION_FORM", formStoreModule);
    }
    if (!this.$store.hasModule("CLIENT_CONSULTATION_FORM_VALUES")) {
      this.$store.registerModule(
        "CLIENT_CONSULTATION_FORM_VALUES",
        formValuesStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENT_CONSULTATION_FORM");
    this.$store.unregisterModule("CLIENT_CONSULTATION_FORM_VALUES");
  },
});
