
















import Vue from "vue";

export default Vue.extend<any, any, any, any>({
  name: "Signature",
  data: () => ({
    signed: false,
    submitted: false,
  }),
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      if (isEmpty) {
        this.signed = false;
        this.submitted = true;
      } else {
        this.signed = true;
        this.submitted = false;
        return data;
      }

      return null;
    },
  },
});
